body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  transition: all 0.25s linear;
  background: rgb(25, 33, 42);
}
a {
  text-decoration: none;
  color: "#FA8072";
}

.footer {
  margin-top: 2rem;
  font-style: italic;
  text-align: right;
  font-size: 14px;
}

.container {
  max-width: 800px;
  margin: 4rem auto;
  min-height: 100vh;
  color: whitesmoke;
}
.center-text {
  text-align: center;
}

.header {
  min-height: 10vh;
  margin: 0 auto;
}

.state {
  margin-top: 2rem;
}

.box-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  vertical-align: middle;
}

.card {
  padding: 40px 0;
  margin: 10px 0;
  min-width: 250px;
  border-radius: 6px;
  color: white;
  align-items: center;
  flex-direction: column;
  display: flex;
  background-color: rgb(33, 43, 54);
}

.label {
  font-size: 14px;
  font-style: italic;
  color: whitesmoke;
}

.menu-flags {
  /* color: black;
  background-color: white;
  height: 50px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin: 0 !important; */
}

.flag-select__btn {
  background-color: white !important;
}

.yellow {
  color: #d8bfd8;
}
